<template>
  <el-card id="callLog">
    <header>
      <h2 style="display: flex; align-items: center">
        通话记录<span v-if="showTitleText"
          >(通话记录:系统保留12个月；通话录音:默认保留15天)</span
        >

        <el-tooltip
          class="item"
          effect="dark"
          content="点击查看详情说明"
          placement="top"
        >
          <img
            v-if="!showTitleText"
            src="@/assets/images/what.png"
            alt=""
            style="width: 30px; height: 30px; cursor: pointer"
            @click="showTitleText = true"
          />
        </el-tooltip>
      </h2>
      <div class="search">
        <div class="left">
          <div class="left-wrap" style="width: 100%">
            <span style="width: 50px">时间</span>
            <el-date-picker
              v-model="value"
              type="datetimerange"
              :shortcuts="shortcuts"
              @change="getDate"
              :clearable="false"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="search">
        <div class="left">
          <div
            class="left-wrap"
            v-if="
              roleName == '管理员' || kefuParentId == 3555 || role == '客服'
            "
          >
            <span>请选择</span>
            <el-select
              v-model="selectId"
              placeholder="请选择企业"
              filterable
              size="mini"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap" v-if="roleName == '企业'">
            <span>请选择</span>
            <el-select
              v-model="selectId"
              placeholder="请选择人员"
              filterable
              size="mini"
            >
              <el-option
                v-for="item in departList"
                :key="item.user_id"
                :label="item.realname"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap" v-if="roleName == '组长'">
            <span>请选择</span>
            <el-select
              v-model="selectId"
              placeholder="请选择人员"
              filterable
              v-if="roleName == '组长'"
              size="mini"
            >
              <el-option
                v-for="item in staffList"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap" v-if="roleName != 'admin'">
            <span>主叫</span>
            <el-input
              v-model="activePhone"
              placeholder="请输入完整主叫号码"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>被叫</span>
            <el-input
              v-model="passivePhone"
              placeholder="请输入完整被叫号码"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>录音(秒)</span>
            <el-input
              v-model="second"
              placeholder="搜索大于等于输入秒数的录音"
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-select
            filterable
            v-model="callType"
            size="mini"
            @change="getList()"
            style="margin-right: 10px"
          >
            <el-option
              v-for="item in callTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="mini"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr />
    <main>
      <el-table
        :data="tableData"
        border
        cell-mouse-enter="mouseEnter"
        class="table"
        ref="multipleTable"
        v-loading="loading"
        element-loading-text="拼命加载中..."
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="id" label="id" align="center"></el-table-column>
        <el-table-column
          prop="activePhone"
          label="主叫"
          align="center"
          v-if="
            (!hideNum && id != 5051) ||
            realname == 'admin' ||
            roleName == '企业'
          "
        ></el-table-column
        ><el-table-column
          prop="phone"
          label="主叫"
          align="center"
          v-if="
            id != 5051 && roleName != '企业' && hideNum && realname != 'admin'
          "
        ></el-table-column>
        <el-table-column
          prop="passivePhone"
          label="被叫"
          align="center"
          v-if="
            (!hideNum && id != 5051) ||
            realname == 'admin' ||
            roleName == '企业'
          "
        ></el-table-column>
        <el-table-column
          prop="phone1"
          label="被叫"
          align="center"
          v-if="
            id != 5051 && roleName != '企业' && hideNum && realname != 'admin'
          "
        ></el-table-column>
        <el-table-column
          prop="seconds"
          label="秒数(s)"
          sortable
          align="center"
        ></el-table-column>
        <el-table-column
          prop="callStarttime"
          label="开始时间"
          sortable
          align="center"
        ></el-table-column>
        <el-table-column
          prop="callEndtime"
          sortable
          label="结束时间"
          align="center"
        ></el-table-column>
        <el-table-column label="录音" align="center">
          <template #default="scope">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="startaudio(scope)"
              v-if="scope.row.recordUrl"
              size="mini"
              >查看录音</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 录音弹出框 -->
    <el-dialog title="录音" :visible.sync="Showaudio" width="30%">
      <div style="margin-bottom: 20px">
        如无法播放录音，可清除浏览器缓存
        按键(ctrl+shift+delet)，重新启动浏览器，重新输入网址http://sys.cddmt.cn，重新登录即可
      </div>
      <div style="display: flex">
        <audio :src="currentAudio" controls="controls"></audio>
        <a
          :href="recordUrl"
          target="_blank"
          rel="noopener noreferrer"
          style="
            line-height: 54px;
            background: pink;
            padding: 0 30px;
            box-sizing: border-box;
            border-radius: 30px;
            margin-left: 10px;
            color: #fff;
          "
          >无法播放点这里</a
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { userFindlist, userList, userOperatorsFindlist } from "@/api/user";
import { phoneFindUser } from "@/api/phone";
import { callLogList, callLogList1, callLogZhiHuList } from "@/api/callLog";
export default {
  data() {
    return {
      callType: 1,
      callTypeOptions: [
        {
          label: "线路拨打",
          value: 1,
        },
        {
          label: "手机直呼",
          value: 2,
        },
      ],
      timer: null,
      pickerOneDate: "",
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = "";
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000; // 15天
            let maxTime = this.pickerOneDate + day;
            let minTime = this.pickerOneDate - day;
            return time.getTime() > maxTime || time.getTime() < minTime;
          } else {
            // return time.getTime() > Date.now()
          }
        },
      },
      loading: false,
      showTitleText: false,
      role: null,
      second: null,
      userId: null,
      tableData: [],
      multipleSelection: [],
      delList: [],
      address: "",
      form: {},
      activePhone: null,
      passivePhone: null,
      value: "",
      companyList: [],
      departList: [],
      staffList: [],
      selectId: null,
      callStarttime: null,
      callEndtime: null,
      shortcuts: [
        {
          text: "最近7天",
          value: [new Date().getTime() - 3600 * 1000 * 24 * 7, new Date()],
        },
        {
          text: "最近15天",
          value: [new Date().getTime() - 3600 * 1000 * 24 * 15, new Date()],
        },
        {
          text: "最近30天",
          value: [new Date().getTime() - 3600 * 1000 * 24 * 30, new Date()],
        },
      ],
      id: null,
      callid: null,
      url: "",
      recordUrl: null,
      Showaudio: false,
      currentAudio: "",
      audioPaused: false,
      encryption: "",
      roleName: null,
      realname: null,
      hideNum: false,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.$nextTick(function () {
      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      console.log("hideNum参数", this.hideNum);
    });
    this.id = localStorage.getItem("id") * 1;
    this.roleName = localStorage.getItem("roleName");
    this.realname = localStorage.getItem("realname");
    this.encryption = localStorage.getItem("encryption");
    this.kefuParentId = localStorage.getItem("parentId");
    this.role = localStorage.getItem("role");

    let rootDate = new Date().toLocaleDateString();

    if (this.roleName == "管理员") {
      this.getCompany();
    } else if (this.roleName == "企业") {
      this.getDepart();
      this.callStarttime =
        this.formatDate(new Date()).substring(0, 10) + " 00:00:00";
      this.callEndtime =
        this.formatDate(new Date()).substring(0, 10) + " 23:59:59";
      this.value = [
        new Date(rootDate + " 00:00:00"),
        new Date(rootDate + " 23:59:59"),
      ];
    } else if (this.kefuParentId == 3555 || this.role == "客服") {
      this.getStaff();
    } else if (this.roleName == "组长") {
      this.getStafB();
      this.selectId = localStorage.getItem("id") * 1;
    }
    this.callStarttime =
      this.formatDate(new Date()).substring(0, 10) + " 00:00:00";
    this.callEndtime =
      this.formatDate(new Date()).substring(0, 10) + " 23:59:59";
    this.value = [
      new Date(rootDate + " 00:00:00"),
      new Date(rootDate + " 23:59:59"),
    ];

    this.getList();
  },
  watch: {
    callStarttime() {
      if (this.callStarttime == "") {
        this.callStarttime = null;
      }
    },
    callEndtime() {
      if (this.callEndtime == "") {
        this.callEndtime = null;
      }
    },
  },
  methods: {
    //admin获取所有公司
    getCompany() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res = userFindlist(params);
      console.log("admin获取所有公司", res);
      res.then((res) => {
        this.companyList = res.data.data;
      });
    },
    //企业获取所有部门
    getDepart() {
      let params = {
        departId: null,
      };
      let res = userList(params);
      res.then((res) => {
        this.departList = res.data.data;
      });
    },
    //客服获取所有公司
    getStaff() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let company = userOperatorsFindlist(params);

      company.then((res) => {
        this.companyList = res.data.data;
      });
    },
    //组长获取所有人员
    getStafB() {
      let staff = phoneFindUser();
      staff.then((res) => {
        this.staffList = res.data.data.user;
      });
    },
    getDate(date) {
      this.callStarttime = this.formatDate(date[0]);
      this.callEndtime = this.formatDate(date[1]);
    },
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    startaudio(row) {
      this.recordUrl = row.row.recordUrl;
      if (
        row.row.recordUrl == "略" ||
        row.row.recordUrl == "" ||
        row.row.recordUrl == null
      ) {
        Message.warning("暂无音频");
      } else {
        console.log(row.row);
        this.currentAudio = row.row.recordUrl;
        this.Showaudio = true;
      }
    },
    // 展示
    getList() {
      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        let params;
        this.loading = true;
        if (this.kefuParentId == 3555 || this.role == "客服") {
          params = {
            page: this.page,
            size: this.size,
            parentId: this.selectId,
            passivePhone: this.passivePhone, //根据被叫查
            callStarttime: this.callStarttime,
            callEndtime: this.callEndtime,
            activePhone: this.activePhone, //根据主叫查
            second: this.second,
          };
          let res = {};
          res = callLogList1(params);

          res.then((res) => {
            if (res.data.success == false) {
              // this.$router.push('/403')
              this.loading = false;
            } else {
              if (res.data.statusCode == "50000") {
              } else {
                this.tableData = res.data.data;
                this.pageTotal = res.data.total;
              }
              res.data.data.forEach((item) => {
                if (item.activePhone != null) {
                  let str = item.activePhone;
                  item.phone = str.substring(0, 3) + "****" + str.substring(7);
                }
                if (item.passivePhone != null) {
                  let str1 = item.passivePhone;
                  item.phone1 =
                    str1.substring(0, 3) + "****" + str1.substring(7);
                }
              });
              this.loading = false;
            }
          });
        } else {
          if (this.roleName == "管理员") {
            params = {
              page: this.page,
              size: this.size,
              parentId: this.selectId,
              passivePhone: this.passivePhone, //根据被叫查
              callStarttime: this.callStarttime,
              callEndtime: this.callEndtime,
              activePhone: this.activePhone, //根据主叫查
              second: this.second,
            };
          } else {
            params = {
              page: this.page,
              size: this.size,
              userId: this.selectId,
              passivePhone: this.passivePhone, //根据被叫查
              callStarttime: this.callStarttime,
              callEndtime: this.callEndtime,
              activePhone: this.activePhone, //根据主叫查
              second: this.second,
            };
          }
          let res = {};
          if (this.callType == 1) {
            // 线路
            res = callLogList(params);
          } else if (this.callType == 2) {
            // 直呼
            res = callLogZhiHuList(params);
          }
          res.then((res) => {
            if (res.data.success == false) {
              // this.$router.push('/403')
              this.loading = false;
            } else {
              if (res.data.statusCode == "50000") {
              } else {
                this.tableData = res.data.data;
                this.pageTotal = res.data.total;
              }
              res.data.data.forEach((item) => {
                if (item.activePhone != null) {
                  let str = item.activePhone;
                  item.phone = str.substring(0, 3) + "****" + str.substring(7);
                }
                if (item.passivePhone != null) {
                  let str1 = item.passivePhone;
                  item.phone1 =
                    str1.substring(0, 3) + "****" + str1.substring(7);
                }
              });
              this.loading = false;
            }
          });
        }
        this.timer = null;
      }, 500);
    },
    //搜索查询
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    //重置刷新按钮
    reset() {
      let rootDate = new Date().toLocaleDateString();
      this.second = null;
      this.passivePhone = null;
      this.callStarttime = null;
      this.callEndtime = null;
      this.callStarttime =
        this.formatDate(new Date()).substring(0, 10) + " 00:00:00";
      this.callEndtime =
        this.formatDate(new Date()).substring(0, 10) + " 23:59:59";
      this.value = [
        new Date(rootDate + " 00:00:00"),
        new Date(rootDate + " 23:59:59"),
      ];
      this.selectId = null;
      this.activePhone = null;
      this.getList();
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278fe;
  border-color: #3278fe;

  &:hover {
    opacity: 0.7;
  }
}

#callLog {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;

        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          .el-select {
            width: 100%;
          }

          span {
            margin: 4px 10px;
            width: 60px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>
