/**
 * callLog
 * 通话记录
 */

import request from '@/utils/request'

// 
export const callLogList = (data) => {
    return request({
        method: 'post',
        url: '/callLog/list',
        data
    })
}
// 
export const callLogList1 = (data) => {
    return request({
        method: 'post',
        url: '/callLog/list1',
        data
    })
}

// 通话记录 手机直呼
export const callLogZhiHuList = (data) => {
	return request({
		url: "/callLog/list-direct",
		method: "post",
		data
	});
};
